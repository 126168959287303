import "index.css"
import "syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")

// Trap focus within an element
function trapFocus(elem, firstTabbableFocused = true) {
  const tabbable = Array.from(elem.querySelectorAll('select, input, textarea, button, a')).filter(el => el.offsetParent !== null);

  const firstTabbable = tabbable[0];
  const lastTabbable = tabbable[tabbable.length - 1];

  if (firstTabbableFocused && firstTabbable) {
    /* set focus on first input */
    firstTabbable.focus();
  }

  /* redirect last tab to first input */
  if (lastTabbable) {
    lastTabbable.addEventListener('keydown', (e) => {
      if (e.key === 'Tab' && !e.shiftKey) {
        e.preventDefault();
        firstTabbable.focus();
      }
    });
  }

  /* redirect first shift+tab to last input */
  if (firstTabbable) {
    firstTabbable.addEventListener('keydown', (e) => {
      if (e.key === 'Tab' && e.shiftKey) {
        e.preventDefault();
        lastTabbable.focus();
      }
    });
  }
}

document.addEventListener("DOMContentLoaded", function() {
  // Mobile Menu
  const btn = document.querySelector("button.mobile-menu-button");
  const menu = document.querySelector(".mobile-menu");
  btn.addEventListener("click", () => {
    menu.classList.toggle("hidden");
  });

  // Dropdown Navigation Menu
  const dropdownButton = document.getElementById("dropdownButton");
  const dropdownMenu = document.getElementById("dropdownMenu");

  function toggleDropdown(dropdownButton, dropdownMenu) {
    const isVisible = dropdownMenu.classList.contains("opacity-100");

    if (isVisible) {
      dropdownMenu.classList.remove("opacity-100", "visible", "block");
      dropdownMenu.classList.add("opacity-0", "invisible", "hidden");
      dropdownButton.classList.remove("text-gray-900");
      dropdownMenu.setAttribute('aria-hidden', true);
    } else {
      dropdownMenu.classList.remove("opacity-0", "invisible", "hidden");
      dropdownMenu.classList.add("opacity-100", "visible", "block");
      dropdownButton.classList.add("text-gray-900");
      dropdownMenu.setAttribute('aria-hidden', false);
      trapFocus(dropdownMenu);
    }
  }

  // Close trapFocus and return focus initiator
  document.querySelectorAll('.js-focus-close').forEach(element => {
    element.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        const focusTarget = e.target.getAttribute('data-focus-target');
        document.getElementById(focusTarget).focus();
      }
    });
  });

  // Open dropdown when clicking on button
  dropdownButton.addEventListener("click", (event) => {
    toggleDropdown(dropdownButton, dropdownMenu);
  });

  // Open dropdown when pressing enter or space
  dropdownButton.addEventListener("keydown", (event) => {
    if (event.key === "Enter" || event.key === " ") {
      toggleDropdown(dropdownButton, dropdownMenu);
    }
  });

  // Close dropdown when clicking outside
  document.addEventListener("click", (event) => {
    if (!dropdownButton.contains(event.target) && !dropdownMenu.contains(event.target)) {
      dropdownMenu.classList.remove("opacity-100", "visible", "block");
      dropdownMenu.classList.add("opacity-0", "invisible", "hidden");
      dropdownButton.classList.remove("text-gray-900");
    }
  });

  // Close dropdown when pressing escape
  document.addEventListener("keydown", (event) => {
    if (event.key === "Escape") {
      dropdownMenu.classList.remove("opacity-100", "visible", "block");
      dropdownMenu.classList.add("opacity-0", "invisible", "hidden");
      dropdownButton.classList.remove("text-gray-900");
      dropdownButton.focus();
    }
  });
});